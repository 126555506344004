<img src="assets/img/physical-toy/teddy-toy@3x.jpg" class="teddy map" />
<svg
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="0 0 1080 1080"
  style="enable-background: new 0 0 1080 1080"
  xml:space="preserve"
  id="teddypaths"
>
  <g id="paths">
    <path
      class="head"
      (click)="selectPart('head')"
      (mouseenter)="selectPart('head')"
      (mouseleave)="selectPart('')"
      d="M390.5,243.7c0-13.1,0.9-21.7,0.9-25.7c0-4-3.7-6.2-7.5-8.4c-3.9-2.2-40.7-18.9-40.7-61.7s32.3-70.3,59.6-74.7
      		s48.3,9,56,17.2c7.7,8.3,11.7,9.5,15.8,7.9s31.6-16.7,68.3-16.7s69.4,14.5,74.7,17.6c5.3,3.1,8.6,4.6,11.4,1.5
      		c2.8-3.1,23.9-29.9,59.6-22.4c35.8,7.5,60.2,43.7,60.2,75.1s-20,48.6-31,55.8c-11,7.2-16.7,8.3-16.9,13.9
      		c-0.2,5.7,4.6,53.6-20.4,98.7s-49.7,62-137.1,62S437.6,359.3,422.2,338C406.8,316.8,390.5,281.4,390.5,243.7z"
    />
    <path
      class="limbs"
      (click)="selectPart('limbs')"
      (mouseenter)="selectPart('limbs')"
      (mouseleave)="selectPart('')"
      d="M518.1,945.2c-0.2-14.7-5.9-45.2-17.2-52.5c2.1-20.5,3.8-55.5,3.6-71.5c-0.2-16-4.8-51.4-9.8-71.2
      		s-4.4-23.2-4.4-23.2s6.2,0.9,11.6,1.3c-5.6-2.3-10.8-4.6-14.9-7c-7.9-4.6-29.8-16.1-41.4-20.9c-5-2.1-11-5.5-16.7-10l-0.1,5.6
      		c-10,5.9-23,18.2-32.4,38.7c-9.4,20.5-16.1,59.6-16.1,86.7c0,36.8,4.6,67.5,4.6,67.5c-7.5,8-23,26.8-28.9,56.9
      		c-5.9,30.1-3.8,48.1,4.6,56.5c8.4,8.4,28.3,23,60.1,23.2c31.8,0.2,66.4-4,85.2-25.5C524.6,978.3,518.3,959.9,518.1,945.2z
      		 M354.2,643c11.4-14.2,22.8-33.8,29-48.2c4.3-10.1,14.6-33.5,21.7-48.8c0.6-5.4,1.3-10.6,1.9-15.4c2.4-17.7,5.5-31.7,7.6-37.5
      		c2.2-5.8,10.8-29.1,12.2-37.2c1.1-6.3,1-16.9,0.8-24.1c-3.7,2.5-7.7,6.2-10.4,10.4c-3.4,5.5-6.6,12.1-6.6,12.1
      		c-5-4.1-8.9-9.6-24-8.7c-15.1,0.9-46.1,12.3-68.5,53.7s-29,70.8-33.6,102.3c-0.7,2.5-2.7,3.2-7.3,7.1c-4.6,3.9-26.7,23.8-26,62.4
      		c0.7,38.6,20.6,61.9,31.5,68.5c11,6.6,36.3,14.2,52.8,1.1C351.9,727.8,366.7,702.6,354.2,643z"
    />
    <path
      class="torso"
      (click)="selectPart('torso')"
      (mouseenter)="selectPart('torso')"
      (mouseleave)="selectPart('')"
      d="M427.5,426.7c0-8.5,0.5-11.8,9.6-18.2c9.1-6.3,41.8-16.3,57.8-15.4c16,0.9,19.9,4.5,24.2,5.8
      		s14.7,2.6,24.9,2.6c10.2,0,27.1-1.9,31.6-3.5c4.5-1.6,7.8-4.8,18.4-4.2s37.2,4.6,50.7,12.1s15.1,12,15.6,18.9
      		c0.4,6.9,2,26.8,2.3,29.4c0.3,2.6,0.9,9.1,3.9,15.4s10.4,35.7,13.5,56.3c3.2,20.6,5.6,49.1,5.8,54.6c0.1,5.5,0.4,6.5,1.4,7.3
      		c1,0.9,1.9,2.9,1.7,6.9c-0.1,4-0.6,6.6-1.4,7.5s-0.1,12.7-0.9,21.8c-0.7,9.1-5.2,41.6-16.7,53.3s-14.7,15.8-22.2,19.3
      		c-7.5,3.5-65.8,34-72.6,37c-9.8,8.5-18.3,12-29.2,12c-11,0-23.8-7.1-28.4-11.2c-10.4-4.5-22.6-8.8-30.5-13.4
      		c-7.9-4.6-29.8-16.1-41.4-20.9c-11.5-4.8-28.1-16.6-34.7-32.3c-6.6-15.7-11.4-43.2-9.8-65.8c-2.2-2.2-2-5.6-2-8.6s0.4-6.3,3-6.8
      		c0-15.7,2.3-38.3,4.8-56c2.4-17.7,5.5-31.7,7.6-37.5s10.8-29.1,12.2-37.2C428.2,447.8,427.5,432.6,427.5,426.7z"
    />
    <circle
      class="coin"
      (click)="selectPart('coin')"
      (mouseenter)="selectPart('coin')"
      (mouseleave)="selectPart('')"
      cx="546.5"
      cy="571.5"
      r="78.8"
    />
    <path
      class="right-hand"
      (click)="selectPart('right-hand')"
      (mouseenter)="selectPart('right-hand')"
      (mouseleave)="selectPart('')"
      d="M729.4,643.6c-7.6,19-14,94.3,31.5,100.4c14.6,1.8,26.3-3.2,39.1-15.2c12.8-12,26-40.9,23.6-68.9
      		c-2.3-28-12.3-46.7-28.3-56c-1.8,15.2-16.6,27.1-29.8,33.9C752.5,644.4,737.9,647.6,729.4,643.6z"
    />
    <path
      class="right-foot"
      (click)="selectPart('right-foot')"
      (mouseenter)="selectPart('right-foot')"
      (mouseleave)="selectPart('')"
      d="M574.2,897.3c0,0-13.7,13.1-17.7,43.3c-4,30.3-5.1,48.8,13.4,65.3c18.5,16.5,52.3,22.3,75.7,22
      		s53.6-4.8,67.1-23.6s6.3-48.9,4.1-59.7s-2.8-29.9-26.6-54.1c-6.8,24-13.2,50.3-46.6,53.4c-33.4,3.1-63-6.6-65.5-18.9
      		C575.7,912.9,574.2,900.6,574.2,897.3z"
    />
    <text transform="matrix(1 0 0 1 75 228.1499)" class="svg-text" [ngClass]="{ visible: selected === 'head' }">
      Magnetic head
    </text>
    <text transform="matrix(1.0339 0 0 1 70 393.0337)" [ngClass]="{ visible: selected === 'limbs' }">
      <tspan x="0" y="0" class="svg-text">Articulated</tspan>
      <tspan x="0" y="34.6" class="svg-text">head, arms</tspan>
      <tspan x="0" y="69.3" class="svg-text">&amp; legs</tspan>
    </text>
    <text transform="matrix(1.0339 0 0 1 720.759 312.1259)" [ngClass]="{ visible: selected === 'coin' }">
      <tspan x="0" y="0" class="svg-text">24k gold plated</tspan>
      <tspan x="0" y="34.6" class="svg-text">stainless steel</tspan>
      <tspan x="0" y="69.3" class="svg-text">coin</tspan>
    </text>
    <text
      transform="matrix(1.0339 0 0 1 794.1167 459.7441)"
      class="svg-text"
      [ngClass]="{ visible: selected === 'torso' }"
    >
      NFC chip inside
    </text>
    <text transform="matrix(1.0339 0 0 1 848.0081 758.1959)" [ngClass]="{ visible: selected === 'right-hand' }">
      <tspan x="0" y="0" class="svg-text">Magnetic</tspan>
      <tspan x="0" y="34.6" class="svg-text">hands</tspan>
    </text>
    <text transform="matrix(1.0339 0 0 1 850.8131 900.2047)" [ngClass]="{ visible: selected === 'right-foot' }">
      <tspan x="0" y="0" class="svg-text">Soft rubber</tspan>
      <tspan x="0" y="34.6" class="svg-text">feet with</tspan>
      <tspan x="0" y="69.3" class="svg-text">Toymint</tspan>
      <tspan x="0" y="103.9" class="svg-text">pattern</tspan>
    </text>
    <g class="arrow head" [ngClass]="{ visible: selected === 'head' }">
      <g>
        <line class="svg-stroke" x1="323" y1="219.5" x2="431.6" y2="250.7" />
        <g>
          <polygon class="svg-arrowhead" points="452,256.5 419.9,260 430.1,250.2 426.7,236.6 				" />
        </g>
      </g>
    </g>
    <g class="arrow limbs" [ngClass]="{ visible: selected === 'limbs' }">
      <g>
        <line class="svg-stroke" x1="267" y1="377.6" x2="368.9" y2="321.7" />
        <g>
          <polygon class="svg-arrowhead" points="387.5,311.5 367.2,336.6 367.5,322.5 355.5,315.2 				" />
        </g>
      </g>
    </g>
    <g class="arrow limbs" [ngClass]="{ visible: selected === 'limbs' }">
      <g>
        <line class="svg-stroke" x1="266" y1="417.5" x2="305.2" y2="457.4" />
        <g>
          <polygon class="svg-arrowhead" points="320,472.5 290.4,459.7 304.1,456.3 307.8,442.7 				" />
        </g>
      </g>
    </g>
    <g class="arrow limbs" [ngClass]="{ visible: selected === 'limbs' }">
      <g>
        <line class="svg-stroke" x1="192" y1="457.5" x2="378.3" y2="706.3" />
        <g>
          <polygon class="svg-arrowhead" points="391,723.2 363.4,706.6 377.4,705 382.9,692 				" />
        </g>
      </g>
    </g>
    <g class="arrow coin" [ngClass]="{ visible: selected === 'coin' }">
      <g>
        <line class="svg-stroke" x1="708" y1="344.6" x2="606.4" y2="484.4" />
        <g>
          <polygon class="svg-arrowhead" points="594,501.5 601.7,470.2 607.4,483.1 621.4,484.5 				" />
        </g>
      </g>
    </g>
    <g class="arrow torso" [ngClass]="{ visible: selected === 'torso' }">
      <g>
        <line class="svg-stroke" x1="783.9" y1="450.5" x2="662.3" y2="513.7" />
        <g>
          <polygon class="svg-arrowhead" points="643.5,523.5 664.4,498.9 663.7,513 675.7,520.5 				" />
        </g>
      </g>
    </g>
    <g class="arrow right-hand" [ngClass]="{ visible: selected === 'right-hand' }">
      <g>
        <line class="svg-stroke" x1="837" y1="747.9" x2="797.1" y2="698" />
        <g>
          <polygon class="svg-arrowhead" points="783.9,681.5 812,697.2 798.1,699.3 793,712.4 				" />
        </g>
      </g>
    </g>
    <g class="arrow right-foot" [ngClass]="{ visible: selected === 'right-foot' }">
      <g>
        <line class="svg-stroke" x1="839" y1="890.6" x2="686.3" y2="964.7" />
        <g>
          <polygon class="svg-arrowhead" points="667.3,973.9 688.8,949.9 687.7,964 699.4,971.9 				" />
        </g>
      </g>
    </g>
  </g>
</svg>
