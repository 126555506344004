<header-component></header-component>

<div id="teddyChatComponent">
  <div class="container">
    <div class="row">
      <div class="col-lg-3"></div>
      <div class="col-12 col-lg-6 content">
        <iframe [src]="iframeContent"></iframe>
      </div>
    </div>
  </div>
</div>

<footer-component></footer-component>
