export const environment = {
    production: false,
    siteUrl: "https://test.club.mintedteddy.com",
    apiUrl: "https://api.721.studio",
    ipfsUrl: "https://ipfs.mintedteddy.com/ipfs",
    contractId: "1cb11b59-b62f-4505-9f53-b1d0da07e6ab",
    infuraUrl: "https://mainnet.infura.io/v3/fd5476cbd80a4d4a8c4d7817c60e735f",
    chain: "0x1",
    network: "1",
    openSeaUrl: "https://opensea.io",
    teddiesContract: "undefined",
    planetContract: "undefined",
};
