<header-component></header-component>

<div id="teddyToyComponent" class="container-fluid">
  <!-- Hero Video -->
  <div class="row">
    <div class="col-12 teddy-video-container">
      <iframe
        src="https://player.vimeo.com/video/782825795?h=260f8eadba&autoplay=1&loop=1&autopause=0&muted=1&controls=0"
        width="100%"
        height="550px"
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        muted
        allowfullscreen
      ></iframe>
    </div>
  </div>
  <!-- Intro Section -->
  <div class="row content-wrapper">
    <div class="col-lg-2"></div>
    <div class="col-12 col-lg-8 content pt-2">
      <div class="heading d-flex flex-column align-items-center">
        <h1 class="display text-center mt-5 mb-3">
          Discover the Magic of TeddyGPT with the Minted Teddy Explorer: Your Child's New Best Friend!
        </h1>
      </div>
      <div class="row py-5">
        <div class="col-12 col-md-5 mb-5">
          <app-teddy-toy-features class="teddy-wrapper"></app-teddy-toy-features>
        </div>
        <div class="col-12 col-md-7 pb-4">
          <h2 class="text-yellow">Introducing... <br />The Minted Teddy Explorer! 🧸🚀</h2>
          <h3 class="reversed">
            This physical toy combines the old-world charm of a squishy-squashy teddy bear that all kids love, with
            tomorrow's cutting-edge technology.
          </h3>
          <p class="reversed">
            The toy is constructed with such elegance that your kiddo will feel like a real grown-up to be entrusted
            with such a delicate vessel. However, the magic truly begins when your child unlocks their digitized teddy
            through this minted piece of art.
          </p>
          <p class="reversed">
            On the other side of the veil, they will find a digital teddy powered by TeddyGPT, which will allow them to
            engage in interactive conversations (kid-friendly, of course!) and explore the enchanting Mintedverse where
            more teddies live and play.
          </p>

          <h2 class="mt-4 mb-3">First, Some Facts About the Teddy Explorer</h2>
          <!-- <p class="reversed">
            The Minted Teddy Explorer is a 5-inch action figure crafted from eco-friendly bioplastic, boasting
            kid-friendly durability and fully articulated limbs and head for endless hours of imaginative play. With
            magnets embedded in the head and hands, it can also serve as a delightful fridge magnet or be paired with
            magnetic accessories like our inspiring Affirmation Shield.
          </p> -->
          <ul class="reversed">
            <li>5-inch action figure crafted from eco-friendly bioplastic</li>
            <li>kid-friendly durability</li>
            <li>fully articulated limbs and head</li>
            <li>made for endless hours of imaginative play</li>
            <li>magnets embedded in the head and hands</li>
            <li>can serve as a delightful fridge magnet or be paired with magnetic accessories</li>
          </ul>

          <h2 class="mt-4">Next, Some Context to the Minted Teddy Digital Experience</h2>
          <h3 class="highlight mt-4 mb-3">Artisan Designed</h3>
          <div class="d-flex flex-wrap teddies-wrapper">
            <div class="digital-teddy t1"></div>
            <div class="digital-teddy t2"></div>
            <div class="digital-teddy t3"></div>
          </div>
          <p class="reversed">
            Each digital teddy, meticulously designed by renowned Italian artist Claudio Bellini, is a unique piece of
            3D art. Your child will cherish the opportunity to interact with their one-of-a-kind digital companion and
            be the coolest bear on the block.
          </p>

          <h3 class="highlight mt-4 mb-3">Family & Community Centric</h3>
          <p class="reversed">
            As part of the Minted Teddy community, you and your child will connect with fellow collectors and their
            teddies. We'll give you the tools to share stories, experiences, and celebrate being co-creators and
            citizens of the Mintedverse.
          </p>

          <h3 class="highlight mb-3">Thoughtfully Made to be the Perfect Gift for Next-Gen Kids</h3>
          <p class="reversed">
            The Minted Teddy Explorer comes beautifully packaged in a black box adorned with a swanky, gold-stamped
            Minted Teddy logo, suitable to be a gift for any occasion!
          </p>

          <p class="reversed mb-4">
            Give the gift of allowing your child to join Minted Teddy in its formative years, where they can help us
            shape the ever-evolving digital Mintedverse experience. The sky is the limit and we're planning to go beyond
            it 🚀 Give the gift of learning, of exploration, of independence, and critical thinking in a safe and
            adventure-filled experience.
          </p>
          <button (click)="claimTeddy()">Claim a Teddy Explorer Now</button>
        </div>
      </div>
    </div>
  </div>

  <script src="https://player.vimeo.com/api/player.js"></script>
</div>

<footer-component></footer-component>
