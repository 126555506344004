<div id="physicalToyComponent" class="container-fluid">
  <div class="row">
    <div class="col-12 col-md-6 p-0 video-wrapper-container">
      <!-- Video -->
      <div class="video-wrapper">
        <iframe
          src="https://player.vimeo.com/video/739905185?autoplay=1&loop=1&autopause=0&muted=1&background=1"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          muted
        ></iframe>
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </div>
    <app-teddy-toy-features class="col-12 col-md-6 p-0 teddy-wrapper"></app-teddy-toy-features>
  </div>

  <!-- Close -->
  <div class="close-button" (click)="closeToyInfo.emit()"></div>
</div>
