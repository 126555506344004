import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-teddy-toy-features',
  templateUrl: './teddy-toy-features.component.html',
  styleUrls: ['./teddy-toy-features.component.sass'],
})
export class TeddyToyFeaturesComponent implements OnInit {
  selected: string = '';

  constructor() {}

  ngOnInit(): void {}

  selectPart(string: string) {
    this.selected = string;
  }
}
