import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-teddy-toy',
  templateUrl: './teddy-toy.component.html',
  styleUrls: ['./teddy-toy.component.sass'],
})
export class TeddyToyComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}

  claimTeddy() {
    this.router.navigateByUrl('/mint');
  }
}
