import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClubGuard } from './guards/club.guard';
import { NoWeb3Guard } from './guards/no-web3.guard';
import { Web3Guard } from './guards/web3.guard';
import { AccountComponent } from './pages/account/account.component';
import { ClubComponent } from './pages/club/club.component';
import { ConnectComponent } from './pages/connect/connect.component';
import { GetComponent } from './pages/get/get.component';
import { HomeComponent } from './pages/home/home.component';
import { DropScheduleComponent } from './pages/drop-schedule/drop-schedule.component';
import { MintComponent } from './pages/mint/mint.component';
import { NoaccessComponent } from './pages/noaccess/noaccess.component';
import { RedeemComponent } from './pages/redeem/redeem.component';
import { ReserveComponent } from './pages/reserve/reserve.component';
import { InvitationComponent } from './pages/invitation/invitation.component';
import { TokenComponent } from './pages/token/token.component';
import { CatalogComponent } from './pages/catalog/catalog.component';
import { MyTeddiesComponent } from './pages/my-teddies/my-teddies.component';
import { CatalogDetailComponent } from './pages/catalog-detail/catalog-detail.component';
import { MintedverseComponent } from './pages/mintedverse/mintedverse.component';
import { QuadrantComponent } from './pages/quadrant/quadrant.component';
import { TeddyCheckerComponent } from './pages/teddy-checker/teddy-checker.component';
import { PreMintComponent } from './pages/premint/premint.component';
import { ComingSoonComponent } from './pages/coming-soon/coming-soon.component';
// Experimental
import { HomeV2Component } from './experimental/home-v2/home-v2.component';
import { LandingComponent } from './portal/landing/landing.component';
import { ChooseTeddyComponent } from './portal/choose-teddy/choose-teddy.component';
import { ChooseTeddyTwoComponent } from './portal/choose-teddy-two/choose-teddy-two.component';
import { AvatarViewComponent } from './portal/avatar-view/avatar-view.component';
import { LaunchpadComponent } from './portal/launchpad/launchpad.component';
import { MintPlanetComponent } from './pages/mint-planet/mint-planet.component';
import { TeddyExplorersComponent } from './pages/teddy-explorers/teddy-explorers.component';
import { PlanetGuardiansComponent } from './pages/planet-guardians/planet-guardians.component';
import { PlanetGuardiansInviteComponent } from './pages/planet-guardians-invite/planet-guardians-invite.component';
import { MintPlanetInviteComponent } from './pages/mint-planet-invite/mint-planet-invite.component';
import { VideoBirthdayTeddyComponent } from './pages/videos/video-birthday-teddy/video-birthday-teddy.component';
import { VideoTeddyExplorerComponent } from './pages/videos/video-teddy-explorer/video-teddy-explorer.component';
import { PromoVideosComponent } from './pages/promo-videos/promo-videos.component';
import { FaqComponent } from './pages/faq/faq.component';
import { TeddyChatComponent } from './pages/teddy-chat/teddy-chat.component';
import { TeddyToyComponent } from './pages/teddy-toy/teddy-toy.component';

const routes: Routes = [
  { path: 'get', component: GetComponent, data: { robots: 'nofollow, noindex' } },
  /******************** 
  PUBLIC Paths
  ********************/
  {
    path: 'history',
    component: DropScheduleComponent,
    data: {
      title: 'History • Minted Teddy',
      description:
        "Minted Teddy started with a simple question: What if a teddy becomes a kid's greatest asset? The mission is to create the most impactful teddy for future generations...",
      ogTitle: 'History in the Making • Minted Teddy',
      ogImage: '/assets/img/teddies-x4-horizontal.jpg',
    },
  },
  {
    path: 'teddyexplorers',
    component: TeddyExplorersComponent,
    data: {
      title: 'Teddy Explorers • Minted Teddy',
      description:
        'Get your own teddy explorer to discover all the bounty across the Mintedverse. Get ready to embark on a virtual exploration of the Mintedverse...',
      ogTitle: 'Get Your Own Minted Teddy Explorer • Minted Teddy',
      ogImage: '/assets/img/mintedverse/planets/birthday-planet@3x.jpg',
    },
  },
  {
    path: 'minted-teddy-explorer',
    component: TeddyToyComponent,
    data: {
      title: 'Minted Teddy Explorer Toy • Minted Teddy',
      description:
        "Introducing... The Minted Teddy Explorer! 🧸🚀 This physical toy combines the old-world charm of a squishy-squashy teddy bear that all kids love, with tomorrow's cutting-edge technology.",
      ogTitle: 'Get Your Own Minted Teddy Explorer Toy • Minted Teddy',
    },
  },
  {
    path: 'planetguardians',
    component: PlanetGuardiansComponent,
    data: {
      title: 'Planet Guardians • Minted Teddy',
      description:
        'Get your own teddy explorer to discover all the bounty across the Mintedverse. Get ready to embark on a virtual exploration of the Mintedverse...',
      ogTitle: 'Become a Planet Guardian • Minted Teddy',
      ogImage: '/assets/img/mintedverse/planets/toys-planet@3x.jpg',
    },
  },
  {
    path: 'planetguardiansinvite',
    component: PlanetGuardiansInviteComponent,
    data: {
      title: 'Planet Guardians • Minted Teddy',
      description:
        'Get your own teddy explorer to discover all the bounty across the Mintedverse. Get ready to embark on a virtual exploration of the Mintedverse...',
      ogTitle: 'Become a Planet Guardian • Minted Teddy',
      ogImage: '/assets/img/mintedverse/planets/toys-planet@3x.jpg',
    },
  },
  {
    path: 'token/:tokenId',
    component: TokenComponent,
    data: { robots: 'nofollow, noindex' },
  },
  {
    path: 'catalog/mooncats',
    component: CatalogComponent,
    data: {
      title: 'Mooncats • Minted Teddy',
      ogTitle: 'Mooncats @ Minted Teddy',
      ogImage: '/assets/img/mooncat-moon-bg.jpg',
    },
  },
  { path: 'catalog/mooncats/:tokenId', component: CatalogComponent, data: { robots: 'nofollow, noindex' } },
  {
    path: 'catalog/mooncats/:tokenId/detail',
    component: CatalogDetailComponent,
    data: { robots: 'nofollow, noindex' },
  },
  {
    path: 'catalog/brendachen',
    component: CatalogComponent,
    data: {
      title: 'Brenda Chen • Minted Teddy',
      ogTitle: 'Brenda Chen @ Minted Teddy',
    },
  },
  { path: 'catalog/brendachen/:tokenId', component: CatalogComponent, data: { robots: 'nofollow, noindex' } },
  {
    path: 'catalog/brendachen/:tokenId/detail',
    component: CatalogDetailComponent,
    data: { robots: 'nofollow, noindex' },
  },
  {
    path: 'catalog/theodoru',
    component: CatalogComponent,
    data: {
      title: 'Theodoru • Minted Teddy',
      ogTitle: 'Theodoru @ Minted Teddy',
    },
  },
  { path: 'catalog/theodoru/:tokenId', component: CatalogComponent, data: { robots: 'nofollow, noindex' } },
  {
    path: 'catalog/theodoru/:tokenId/detail',
    component: CatalogDetailComponent,
    data: { robots: 'nofollow, noindex' },
  },
  {
    path: 'catalog/williamwilloughbyiii',
    component: CatalogComponent,
    data: {
      title: 'William Willoughby III • Minted Teddy',
      ogTitle: 'William Willoughby III @ Minted Teddy',
    },
  },
  { path: 'catalog/williamwilloughbyiii/:tokenId', component: CatalogComponent, data: { robots: 'nofollow, noindex' } },
  {
    path: 'catalog/williamwilloughbyiii/:tokenId/detail',
    component: CatalogDetailComponent,
    data: { robots: 'nofollow, noindex' },
  },
  {
    path: 'catalog/nextgen',
    component: CatalogComponent,
    data: {
      title: 'Next Gen Teddies • Minted Teddy',
      ogTitle: 'Next Gen Minted Teddies',
    },
  },
  { path: 'catalog/nextgen/:tokenId', component: CatalogComponent, data: { robots: 'nofollow, noindex' } },
  { path: 'catalog/nextgen/:tokenId/detail', component: CatalogDetailComponent, data: { robots: 'nofollow, noindex' } },
  {
    path: 'catalog/series1',
    component: CatalogComponent,
    data: {
      title: 'Series 1 Teddies • Minted Teddy',
      ogTitle: 'Series 1 Minted Teddies',
    },
  },
  { path: 'catalog/series1/:tokenId', component: CatalogComponent, data: { robots: 'nofollow, noindex' } },
  { path: 'catalog/series1/:tokenId/detail', component: CatalogDetailComponent, data: { robots: 'nofollow, noindex' } },
  {
    path: 'catalog',
    component: CatalogComponent,
    data: {
      title: 'Catalog • Minted Teddy',
      ogTitle: 'Minted Teddies Catalog',
    },
  },
  { path: 'catalog/:tokenId', component: CatalogComponent, data: { robots: 'nofollow, noindex' } },
  { path: 'catalog/:tokenId/detail', component: CatalogDetailComponent, data: { robots: 'nofollow, noindex' } },
  {
    path: 'mintedverse',
    component: MintedverseComponent,
    data: {
      title: 'Mintedverse • Minted Teddy',
      description:
        'Zip, zag and zoom through the Mintedverse with your very own Minted Teddy Explorer. Discover all the bits and bounty to be explored in this new digital frontier.',
      ogTitle: 'Explore the Mintedverse • Minted Teddy',
    },
  },
  {
    path: 'mintedverse/quadrant-1',
    component: QuadrantComponent,
    data: {
      title: 'Mintedverse Quadrant 1 • Minted Teddy',
      ogTitle: 'Mintedverse Quadrant 1',
    },
  },
  {
    path: 'mintedverse/quadrant-2',
    component: QuadrantComponent,
    data: {
      title: 'Mintedverse Quadrant 2 • Minted Teddy',
      ogTitle: 'Mintedverse Quadrant 2',
    },
  },
  {
    path: 'mintedverse/quadrant-3',
    component: QuadrantComponent,
    data: {
      title: 'Mintedverse Quadrant 3 • Minted Teddy',
      ogTitle: 'Mintedverse Quadrant 3',
    },
  },
  {
    path: 'mintedverse/quadrant-4',
    component: QuadrantComponent,
    data: {
      title: 'Mintedverse Quadrant 4 • Minted Teddy',
      ogTitle: 'Mintedverse Quadrant 4',
    },
  },
  {
    path: 'mintedverse/quadrant-5',
    component: QuadrantComponent,
    data: {
      title: 'Mintedverse Quadrant 5 • Minted Teddy',
      ogTitle: 'Mintedverse Quadrant 5',
    },
  },
  {
    path: 'mintedverse/quadrant-6',
    component: QuadrantComponent,
    data: {
      title: 'Mintedverse Quadrant 6 • Minted Teddy',
      ogTitle: 'Mintedverse Quadrant 6',
    },
  },
  {
    path: 'coming-soon',
    component: ComingSoonComponent,
    data: {
      title: 'Coming Soon • Minted Teddy',
      ogTitle: 'Coming Soon',
      robots: 'nofollow, noindex',
    },
  },
  {
    path: 'faq',
    component: FaqComponent,
    data: {
      title: 'FAQ • Minted Teddy',
      description:
        'Each and every Minted Teddy has been MINTangible™ certified, which means that the Minted Teddy License Terms are digitally bound...',
      ogTitle: 'Minted Teddy FAQ',
    },
  },
  { path: 'noaccess', component: NoaccessComponent, data: { robots: 'nofollow, noindex' } },
  { path: 'connect', component: ConnectComponent, canActivate: [NoWeb3Guard], data: { robots: 'nofollow, noindex' } }, // if user isn't connected to their wallet, they are redirected here
  /******************** 
  AUTH REQUIRED Paths
  ********************/
  { path: 'account', component: AccountComponent, canActivate: [Web3Guard], data: { robots: 'nofollow, noindex' } },
  {
    path: 'my-teddies',
    component: MyTeddiesComponent,
    canActivate: [Web3Guard],
    data: { robots: 'noindex' },
  },
  /******************** 
  Mint Paths
  ********************/
  { path: 'mint', component: MintComponent, data: { robots: 'nofollow, noindex' } },
  { path: 'mintplanet', component: MintPlanetComponent, data: { robots: 'nofollow, noindex' } },
  { path: 'mintplanetinvite', component: MintPlanetInviteComponent, data: { robots: 'nofollow, noindex' } },
  { path: 'premint', component: PreMintComponent, data: { robots: 'nofollow, noindex' } },
  { path: 'redeem/:voucherId', component: RedeemComponent, data: { robots: 'nofollow, noindex' } },
  //{ path: 'redeem', component: RedeemComponent },
  { path: 'claim-teddy', component: TeddyCheckerComponent, data: { robots: 'nofollow, noindex' } },
  //{ path: 'mint/:generationId', component: MintComponent, data: { robots: 'nofollow, noindex' } },
  //{ path: 'reserve/:invitationId', component: ReserveComponent, data: { robots: 'nofollow, noindex' } },
  { path: 'invitation/:invitationId', component: InvitationComponent, data: { robots: 'nofollow, noindex' } },
  /******************** 
  Promo Paths
  ********************/
  { path: 'video/birthday-teddy', component: VideoBirthdayTeddyComponent, data: { robots: 'nofollow, noindex' } },
  { path: 'video/teddy-explorer', component: VideoTeddyExplorerComponent, data: { robots: 'nofollow, noindex' } },
  { path: 'promo-videos', component: PromoVideosComponent, data: { robots: 'nofollow, noindex' } },
  /******************** 
  Experimental Paths 
  ********************/
  { path: 'teddy-chat', component: TeddyChatComponent },
  { path: 'experimental/home', component: HomeV2Component, data: { robots: 'nofollow, noindex' } },
  { path: 'portal/landing', component: LandingComponent, data: { robots: 'nofollow, noindex' } },
  { path: 'portal/choose-teddy', component: ChooseTeddyComponent, data: { robots: 'nofollow, noindex' } },
  { path: 'portal/choose-teddy-2', component: ChooseTeddyTwoComponent, data: { robots: 'nofollow, noindex' } },
  { path: 'portal/avatar', component: AvatarViewComponent, data: { robots: 'nofollow, noindex' } },
  { path: 'portal/launchpad', component: LaunchpadComponent, data: { robots: 'nofollow, noindex' } },
  /********************
  Catchall 
  ********************/
  {
    path: '',
    component: HomeComponent,
    data: {
      title: 'Home • Minted Teddy',
      ogTitle: 'Home of the Minted Teddy',
      ogImage: '/assets/img/bg/mintedverse-3@3x.jpg',
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
