import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Web3Service } from '../../services/web3.service';

@Component({
  selector: 'header-component',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass'],
})
export class HeaderComponent implements OnInit {
  supportsWeb3: boolean = false;
  url: string = '';
  reservePage: boolean = false;
  showMobileNav: boolean = false;

  constructor(public web3Service: Web3Service, private router: Router) {
    this.url = this.router.url;
    this.reservePage = this.url.includes('invitation');
  }

  async ngOnInit() {
    await this.web3Service.getAccounts();
  }

  async goHome() {
    if (!this.reservePage) {
      await this.router.navigateByUrl('/');
    }
  }

  async gotoDropSchedule() {
    await this.router.navigateByUrl('/history');
  }

  async gotoMyAccount() {
    await this.router.navigateByUrl('/account');
  }

  async gotoCatalog() {
    await this.router.navigateByUrl('/catalog');
  }

  async goToMintedverse() {
    await this.router.navigateByUrl('/mintedverse');
  }

  async connectWallet() {
    await this.web3Service.connectWallet();
  }

  async gotoMyTeddies() {
    await this.router.navigateByUrl('/my-teddies');
  }

  async gotoTeddyChat() {
    await this.router.navigateByUrl('/teddy-chat');
  }

  smartTrim(string: string, maxLength: number) {
    if (!string) return string;
    if (maxLength < 1) return string;
    if (string.length <= maxLength) return string;
    if (maxLength == 1) return string.substring(0, 1) + '...';

    var midpoint = Math.ceil(string.length / 2) + 1;
    var toremove = string.length - maxLength;
    var lstrip = Math.ceil(toremove / 2);
    var rstrip = toremove - lstrip;
    return string.substring(0, midpoint - lstrip) + '...' + string.substring(midpoint + rstrip);
  }

  toggleMobileMenu() {
    this.showMobileNav = !this.showMobileNav;
  }
}
