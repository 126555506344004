import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-physical-toy-info',
  templateUrl: './physical-toy-info.component.html',
  styleUrls: ['./physical-toy-info.component.sass'],
})
export class PhysicalToyInfoComponent implements OnInit {
  @Output() closeToyInfo = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}
}
