import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ContractsService } from 'src/app/services/contracts.service';
import { IpfsService } from 'src/app/services/ipfs.service';
import { Web3Service } from 'src/app/services/web3.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-teddy-chat',
  templateUrl: './teddy-chat.component.html',
  styleUrls: ['./teddy-chat.component.sass'],
})
export class TeddyChatComponent implements OnInit {
  public loading: boolean = true;
  codeId: string = '3db4358a-a2cd-47a7-9827-01b590a5295e';
  iframeContent: SafeUrl = '';

  constructor(
    private router: Router,
    private ipfsService: IpfsService,
    private web3Service: Web3Service,
    private contractService: ContractsService,
    private sanitizer: DomSanitizer
  ) {}

  // Don't think this is useful as is:
  // TODO: Repurpose this function to get codeId
  // Maybe this function isn't needed.
  async getTeddy(selectedContract: any, tokenId: string) {
    const url = await selectedContract.methods.tokenURI(tokenId).call();
    try {
      this.ipfsService.getMetadata(url);

      const metaData = await this.ipfsService.getMetadata(url);
      console.log('----- metaData');
      console.log(metaData);

      this.loading = false;
    } catch (ex) {
      console.log('invalid metadata');
    }
  }

  async ngOnInit() {
    this.iframeContent = this.sanitizer.bypassSecurityTrustResourceUrl(
      `https://og.mintedteddy.com/${this.codeId}/teddychat`
    );

    // Get teddy codeId
    // TODO: Get a teddy's codeId to determine the iframe content url
    const contract = await this.contractService.getContract(environment.contractId);
    const selectedContract = new this.web3Service.web3.eth.Contract(
      JSON.parse(contract.Contract).abi as any,
      contract.Address
    );

    if (this.web3Service.accounts.length > 0) {
      const requests = [];

      try {
        const tokens = await selectedContract.methods.getTokenIds(this.web3Service.accounts[0]).call();
        if (tokens && tokens.length > 0) {
          for (const token of tokens) {
            requests.push(this.getTeddy(selectedContract, token));
          }
        }
      } catch (ex) {}

      Promise.all(requests);
    }
  }
}
